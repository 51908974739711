<template>
  <div class="auth-screen full-screen container-fluid">
    <transition name="fade" mode="out-in">
      <v-app>
        <v-row justify="center" align="center">
          <v-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
          >
          <h1>NO SE ENCONTRO LA PÁGINA</h1>
          <v-btn small @click="save" color="#1E294D" dark :to="{name: 'dimensions'}" > Volver a inicio </v-btn>
          </v-col>
        </v-row>
      </v-app>
    </transition>
  </div>
</template>
<script>
export default {
  name: "NotFound",
  data() {
    return {};
  }
};
</script>
